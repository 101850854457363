.TableOfContents {
  margin-bottom: 4em;
  width: 100%;
}

.TableOfContents .step-title {
  font-size: 1.5em;
}

.TableOfContents .step-section-title {
  text-align: left;
  font-size: 1.1em;
  line-height: 1.5em;
}