.ToolCard {
  text-decoration: none;
  display: inline-block;
  width: 30%;
  margin-bottom: 20px;
}

@media only screen and (max-width: 700px) {
  .ToolCard {
    width: 100%;
  }
}

.ToolCard .screenshot {
  height: 0;
  padding-top: 50%;
}