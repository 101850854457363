body {
  background: #f5f5f5;
}

a {
  text-decoration: none;
}

.primary-text {
  color: #298F6B;
}

.secondary-text {
  color: #467444;
}

.tertiary-text {
  color: #000000;
}

.bordered-text {
  -webkit-text-stroke: 2px white;
}

.red-text {
  color: red;
}

.bold-text {
  font-weight: bold;
}

.white-text {
  color: white;
}

.center-text {
  text-align: center;
}

.tooltip-text {
  font-size: 1rem;
  padding: 0.5rem;
}

.help-text {
  color: grey;
  padding-top: 0.5em;
}

.light-help-text {
  color: lightgrey;
  padding-top: 0.5em;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.bordered {
  border: 1px solid lightgrey;
}

.border-bottom {
  border-bottom: 1px solid lightgrey;
}

.vertically-spaced {
  margin: 0.6em 0 !important;
}

.logo-bar {
  background: #298F6B;
}

.logo {
  padding: 1rem 0;
}

.heart {
  color: pink;
  font-size: 2em;
  position: relative;
  top: 0.1em;
}

.hero-blockquote {
  padding: 5em 10%;
  padding-bottom: 1em;
  margin-bottom: 4rem;
  background: white;
  height: 80%;

  background-image: url('https://www.fiction.tools/asimov.png');
  background-repeat: no-repeat;
  background-position: 77% 140%;
}

.hero-blockquote blockquote {
  border-left: 0.4rem solid white;
  font-size: 3rem;
  color: black;
}

.hero-blockquote blockquote::before {
  content: '“';
}

.hero-blockquote blockquote::after {
  content: '”';
}

.hero-blockquote cite {
  font-size: 2rem;
  font-style: normal;
  background: white;
}

.hero-blockquote cite::before {
  content: "― ";
}

.hero-blockquote .context {
  margin-top: 1.6em;
  font-size: 1.2em;
  width: 80%;
  background: white;
  padding: 0.5em 0;
}