.BadgeHighlighter {
  width: 100%;
  margin-bottom: 5em;
}

.BadgeHighlighter .badge-list {
  background: white;
  padding: 0.3em 0;
}

.BadgeHighlighter .badge-icon {
  font-size: 1.4em;
}